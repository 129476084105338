@import '../../styles/propertySets.css';

.root {
  position: relative;
  width: 100%;
  /* margin-bottom: 100px; */
  /* max-width: calc(100% - 48px);

  @media (--viewportLarge) {
    max-width: unset;
  } */
}

.tabs {
  border: 1px solid var(--customGrayBorder);
  border-radius: 24px;
  overflow: hidden;
  margin: 0 0 30px;
  
  @media (--viewportMLarge) {
    display: flex;
    align-items: center;
  }
}

.tabsItem {
  display: block;
  padding: 9px 0;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  text-align: center;
  cursor: pointer;

  @media (--viewportMLarge) {
    width: calc(100%/3);
  }
  
  &:nth-child(2) {
    border-top: 1px solid var(--customGrayBorder);
    border-bottom: 1px solid var(--customGrayBorder);
    
    @media (--viewportMLarge) {
      border-left: 1px solid var(--customGrayBorder);
      border-right: 1px solid var(--customGrayBorder);
  
      border-top: none;
      border-bottom: none;
    }
  }
}

.tabsItemActive {
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.slider {
  display: block;
  margin: 0 auto;

  @media (--viewportMedium) {
    max-width: 720px;
  }

  @media (--viewportLarge) {
    max-width: 976px;
  }

  @media (--viewportMLarge) {
    max-width: 100%;
  }
    
  & :global(.slick-slider) {
  }
  
  & :global(.slick-list) {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;

    @media (--viewportSmall) {
      width: calc(100% - 152px);
    }
  }

  & :global(.slick-prev),
  & :global(.slick-next) {
    width: 42px;
    height: 36px;
    border-radius: var(--borderRadiusWrapper);

    & path {
      fill: var(--marketplaceColor);
    }

    & path:last-child {
      fill: var(--matterColorLight);
    }
  }

  & :global(.slick-prev) {
    left: 50%;
    transform: translateX(-50px);
    bottom: -60px;
    top: auto;

    @media (--viewportSmall) {
      bottom: auto;
      top: 50%;
      left: 0;
      transform: translateX(0);
    }
  }

  & :global(.slick-next) {
    right: 50%;
    bottom: -60px;
    top: auto;
    transform: translateX(50px);

    @media (--viewportSmall) {
      bottom: auto;
      top: 50%;
      right: 0;
      transform: translateX(0);
    }
  }
}

.sliderItem {
  max-width: 313px;
  display: block !important;
  margin: 0 auto;
  
  @media (--viewportSmall) {
    padding: 14px;
  }
  
  @media (--viewportMLarge) {
    max-width: 313px;
  }
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
