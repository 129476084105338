@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.wrapper {
  background-color: #FCFCFC;
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  position: relative;
  margin: 0 auto 22px;
  padding: 0 24px;

  @media (--viewportMedium) {
    max-width: 1056px;
    display: flex;
  }

  @media (--viewportLarge) {
    max-width: 1592px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* background-color: var(--matterColorNegative); Loading BG color */
  display: flex;
  flex-direction: row;

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.otherImageContainer {
  display: flex;
  margin: 0 0 0 10px;
  height: calc(0.3 * 100vw);
  max-height: 328px;
  min-height: 328px;
  width: 100%;

  & img {
    border-radius: 20px;
    object-fit: cover;
  }
}

.otherImageContainer {

}

.otherImageContainerTwo {

}

.otherImageContainerThree {
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    width: 100%;
    margin-bottom: 9px;
    max-height: 160px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.otherImageContainerFour {
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    width: calc(50% - 5px);
    max-height: 160px;

    &:nth-child(-n+2) {
      margin-bottom: 9px;
    }
    &:last-child {
      width: 100%;
    }
  }
}

.otherImageContainerFive {
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    width: calc(50% - 5px);
    max-height: 160px;

    &:last-child {
      width: calc(50% - 5px);
    }

    &:nth-child(-n+2) {
      margin-bottom: 9px;
    }
  }
}

.twoImageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 15px 0 0;
  justify-content: space-between;
  height: 46%;
}

.rootForImage,
.singleRootForImage,
.rootForSecondImage,
.secondImage,
.rootForThirdImage,
.thirdImage,
.rootForFourthImage,
.fivethImage,
.rootForFivethImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.3 * 100vw);
    max-height: 328px;
    min-height: 328px;
    object-fit: cover;
    border-radius: var(--borderRadiusWrapper);

    &:hover {
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage,
.singleRootForImage {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 612px);
    flex-basis: calc(100% - 612px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.singleRootForImage {
  @media (--viewportLarge) {
    max-width: 100%;
  }
}

.rootForSecondImage {
  height: 50%;
}

.secondImage,
.thirdImage {
  height: 100%;
}

.rootForThirdImage,
.rootForFourthImage {
  height: auto;
  width: 48%;
}

.actionBar {
  position: absolute;
  top: 13px;
  left: 46px;
  width: calc(100% - 92px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  border-radius: var(--borderRadiusWrapper);

  @media (--viewportSmall) {
    display: flex;
    justify-content: space-between;
  }
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  display: inline-block;
  margin: 14px 12px 11px 24px;
  padding: 0;
  color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportSmall) {
    padding: 14px 24px 11px 12px;
    margin: 0;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}


.viewPhotos {
  @apply --marketplaceButtonStyles;
  position: absolute;
  bottom: 18px;
  right: 18px;
  max-width: 120px;
  min-height: 40px;
  padding: 0;

  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  background: var(--matterColorLight);
  font-size: 14px;
  line-height: 17px;
  font-weight: var(--fontWeightSemiBold);

  &:hover,
  &:focus {
    color: var(--matterColorLight);
    border: 1px solid var(--marketplaceColor);
    background: var(--marketplaceColor);
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  padding: 0 24px;

  @media (--viewportMedium) {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1056px;
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1592px;
    padding: 0 36px;
    margin: 0 auto 86px;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    /* margin-top: 50px; */
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    /* margin-top: 50px; */
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 460px);
    flex-basis: calc(100% - 460px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    /* margin-top: 79px; */
    margin-left: 80px;

    /* The calendar needs 312px */
    flex-basis: 320px;
    flex-shrink: 0;
  }

  @media (--viewportMLarge) {
    flex-shrink: unset;
    flex-basis: 380px;
  }
}

.hostedBy {
  display: flex;
  align-items: center;
}

.avatarDesktop {
  display: flex;
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin-right: 7px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1CustomFontStyles;
  margin: 0 0 13px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (--viewportSmall) {
    align-items: center;
    flex-direction: row;
  }
}

.subTitle {
  @apply --marketplaceH2CustomFontStyles;
  color: var(--customGray);
  font-weight: var(--fontWeightMedium);
}

.category {
  min-width: 100px;
  min-height: 30px;
  text-transform: uppercase;
  color: var(--matterColorLight);
  font-size: 14px;
  line-height: 30px;
  background: var(--marketplaceColor);
  border-radius: 22px;
  margin: 0 0 12px 0;
  padding: 0 10px 0 12px;
  font-weight: var(--fontWeightBold);
  text-align: center;

  @media (--viewportSmall) {
    margin: 0 12px 0 0;
  }
}

.categoryRent {
  background: var(--categoryRent);
}

.categoryActivities {
  background: var(--categoryActivities);
}


.authorInfo {

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }

  & .separator {
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }
}

.authorInfoItem {
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightSemiBold);
  margin-bottom: 15px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.authorInfoLabel {
  margin-right: 8px;
  font-weight: var(--fontWeightMedium);
  color: var(--customGray);
}

.authorNameLink {
  color: var(--matterColor);
}

.reviewInfoRating {
  margin: 0 6px;
  font-weight: var(--fontWeightRegular);
}

.reviewInfoTotal {
  color: var(--customBlue);
  text-decoration: underline;
  font-weight: var(--fontWeightRegular);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.section {
  padding: 0 0 35px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--marketplaceColorLight);
}

.sectionTitle {
  /* Font */
  @apply --marketplaceH2CustomFontStyles;
  margin: 0 0 15px;
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  color: var(--customGray);
  line-height: 1.5;
}

.featuresHolder {
  width: 100%;

  @media (--viewportSmall) {
    display: flex;
  }
}

.featuresItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid var(--marketplaceColorLight);
  margin-bottom: 24px;

  @media (--viewportSmall) {
    width: calc(100%/3);
    align-items: center;
    padding: 0 24px;
    margin-bottom: 0;
  }

  @media (--viewportMLarge) {
    padding: 0;
  }
}

.featuresItem:first-child {
  align-items: flex-start;
  padding-left: 0;
}

.featuresItem:last-child {
  border-right: none;
  padding-right: 0;
  margin-bottom: 0;
}

.locationDescriptionHolder {
  margin: 0 0 28px;

  @media (--viewportMLarge) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.locationDescription {
  color: var(--matterColor);
  margin: 0;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  
  @media (--viewportMedium) {
    padding-right: 20px;
  }
}

.legendHolder {
  display: flex;
  margin-top: 15px;

  @media (--viewportMLarge) {
    margin-top: 0;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 18px;

  @media (--viewportSmall) {
    margin-right: 46px;
  }

  &:last-child {
    margin-right: 0;
  }

  & svg {
    margin-right: 10px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 315px;
  width: 100%;

  /* Static map: dimensions are 640px */
  /* max-width: 640px; */
  /* max-height: 640px; */
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  border-radius: var(--borderRadiusWrapper);
  overflow: hidden;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.rules {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.rulesItem {
  width: 100%;

  @media (--viewportMLarge) {
    width: 50%;
  }
}

.seeMore {
  margin-top: 6px;
  color: var(--customBlue);
  text-decoration: underline;
  cursor: pointer;
}

.seeMoreCenter {
  display: block;
  text-align: center;
}

.services {

}

.servicesItem {
  margin-bottom: 20px;

  @media (--viewportMLarge) {
    display: flex;
    justify-content: space-between;
  }
}

.servicesTitle {
  line-height: 40px;
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  word-break: break-word;

  & svg {
    margin: 0 10px 0 0;
  }
}

.servicesPrice {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  font-size: 24px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  @media (--viewportSmall) {
    align-items: center;
    flex-direction: row;
  }

  & > span {
    width: 100%;

    @media (--viewportSmall) {
      width: auto;
    }
  }
}

.servicesPricePerDesc {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  margin-left: 8px;
}

.addBtn,
.removeBtn {
  @apply --marketplaceButtonStyles;
  padding: 0;
  min-height: 44px;
  width: 140px;
  margin: 0 0 0 35px;
}

/* Add to favourites button */

.likeIconButton {
  width: 50px;
  height: 50px;
  border: 1.5px solid #DBEAD3;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (--viewportMLarge) {
    flex-wrap: nowrap;
  }
}

.shareAndFavorites {
  display: flex;
  margin: 0 0 20px;

  @media (--viewportMedium) {
    margin: 0 0 10px;
  }
}

.likeIcon {
  fill: transparent;
}

.likeIconFavourite {
  fill: #2F4E1E;
}

.shareSocialsContainer {
  position: relative;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.shareSocialsList {
  display: flex;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin: 0;
  padding: 7px 10px 8px;
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.shareSocialsListItem {
  display: block;
  height: 20px;
  cursor: pointer;
}

.sociaIcon {
  display: block;
}

.shareIcon {
  fill: transparent;
}

.shareSocialsList .shareSocialsListItem {
  margin-right: 10px;
}

.shareSocialsList .shareSocialsListItem:last-child {
  margin-right: 0;
}

.shareIconContainer {
  width: 50px;
  height: 50px;
  border: 1.5px solid #DBEAD3;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.shareSocialsListWrapper:hover {
  display: block;
}

.shareSocialsList::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 110px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;

  @media (--viewportMLarge) {
    right: 20px;
  }
}

.shareSocialsListWrapper {
  position: absolute;
  top: -42px;
  right: -90px;

  display: none;

  padding-bottom: 10px;

  @media (--viewportMLarge) {
    right: 5px;
  }
}

.googleIcon {
  position: relative;
}

.shareIconContainer:hover + .shareSocialsListWrapper {
  display: block;
}

.removeBtn {
  background-color: var(--failColor);

  &:focus {
    background-color: var(--failColor);
  }
}
